
/*

#B9725B -> #647AA3
#e8d3c9 -> #eff1f5 -> #dde2eb
  
  Farben im Webflow
Farben im Zimmer
Farben in Startsections
Farben im Custom Code
Filter im Custom Code
SB Container
  jobs
mailchimp

falls sec. color
Pfeilexpblau
Pfeiloffenblau

*/
  

  
.scroll-indicator {
    cursor: pointer; 
 z-index:2;
}

.galerysection {

 min-height: calc(40vw + 50px);
}

@media (max-width: 991.5px) {
 .galerysection {

     min-height: calc(40vw + 50px);
 }
 
}

@media (max-width: 479.5px) {
 .galerysection.big {

     min-height: calc(100vw + 500px);
 }
 
}






@media (min-width: 1800px) {
.zimmerimg {
 width: 50%;
 }
 .zimmercontainerbox {
         width: 50%;
         padding-left: 90px;
     
         padding-right: 90px;
     
 }
 .zimmerbox {
         width: 100%;
             transform: none;
     
 
 }
 

}

@media (max-width: 360px) {
.deenlinkcontainer {
 display:none;
}
.navwrap {
 padding-left:0px;
}
.buttongelb-navi {
 transform: translate(-20px)scale(.8);
}
.introparagraph {

 padding-left: 30px;
 padding-right: 30px;
}

.buttongelb-navi-overlay-mob {
 margin-left: -45px;
 transform: scale(.7);
}
.buttongelb-navi-overlay-outline-mob {

 margin-left: -45px;
 transform: scale(.7);
}


}
.gallery-thumbnails {

 display: flex;
 flex-wrap: wrap;
 gap: 10px;
 padding-left: 15px;
 padding-right: 15px;
 justify-content: center;
}

.gallery-thumbnails img {
 /* width: 80px; */
 height: 80px;
 margin: 0;
 cursor: pointer;
}

.gallery-thumbnails img.active {
 border: 2px solid #000;
}


.gallery-image {
 display: none;
}


.gallery-image.active {
 display: block;
}



.gallery-thumbnails .zimmergaleriebild {

border-top-right-radius: 20px;
border-bottom-left-radius: 20px;
}

body {
overflow-x:hidden;
background-color: #FEFDFB;
}

body, p, h1, h2, h3, h4, h5, h6, p.pfliess {

 overflow-wrap: break-word;
 word-wrap: break-word;
 -webkit-hyphens: auto;
 -ms-hyphens: auto;
 -moz-hyphens: auto;
 hyphens: auto;
}

.nohyph {

 overflow-wrap: normal !important;
 word-wrap: normal !important;
 -webkit-hyphens: manual !important;
 -ms-hyphens: manual !important;
 -moz-hyphens: manual !important;
 hyphens: manual;

}

@media (max-width: 991.5px) { 
.noscroll {
overflow: hidden;
height: 100vh;
}
} 

/* width */
::-webkit-scrollbar {
width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
background: #DCAE6B;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #647AA3;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #647AA3;
}

 
/* Track */
.menubereichcont::-webkit-scrollbar-track {
background: #647AA3;
}

/* Handle */
.menubereichcont::-webkit-scrollbar-thumb {
background: #f1e4cd;
}

/* Handle on hover */
.menubereichcont::-webkit-scrollbar-thumb:hover {
background: #f1e4cd;
}



.buttongelbhome:hover, .buttongelbhomemob:hover {

background-color:#f1e4cd !important;
color: #DCAE6B !important;
border-color: #f1e4cd !important;

}

.fullscreensection-wrapper .buttongelbhome:hover, .fullscreensection-wrapper .buttongelbhomemob:hover   {

background-color:#dde2eb !important;
color: #647AA3 !important;
border-color: #dde2eb !important;

}

.w-dyn-item:nth-child(2n) .fullscreensection-wrapper .buttongelbhome:hover, .w-dyn-item:nth-child(2n) .fullscreensection-wrapper .buttongelbhomemob:hover {
 

background-color:#f1e4cd !important;
color: #DCAE6B !important;
border-color:#f1e4cd !important;


}

.zimmerwrap .buttongelbhome:hover, .zimmerwrap .buttongelbhomemob:hover {

background-color:#f1e4cd !important;
color: #DCAE6B !important;
border-color:#f1e4cd !important;


}

.w-dyn-item:nth-child(2n) .zimmerwrap .buttongelbhome:hover, .w-dyn-item:nth-child(2n) .zimmerwrap .buttongelbhomemob:hover {
 

background-color:#dde2eb !important;
color: #647AA3 !important;
border-color: #dde2eb !important;

}


@media (min-width: 991.5px) {
.navigationoverlay {
  height: calc(100vh - 100px);   
}
}

.menubutton {
filter: invert(49%) sepia(12%) saturate(1274%) hue-rotate(181deg) brightness(93%) contrast(84%);
/* old:
filter: invert(63%) sepia(6%) saturate(3915%) hue-rotate(326deg) brightness(80%) contrast(76%);
*/
}
.expanded, .closebutton {
filter: invert(92%) sepia(10%) saturate(787%) hue-rotate(325deg) brightness(105%) contrast(92%);
/* old:
filter: invert(99%) sepia(99%) saturate(344%) hue-rotate(310deg) brightness(99%) contrast(93%);
*/
}
.expanded.lokd, .closebutton:hover, .menubutton:hover {
filter: invert(26%) sepia(39%) saturate(423%) hue-rotate(178deg) brightness(93%) contrast(92%);
/* old:
filter: invert(14%) sepia(4%) saturate(4952%) hue-rotate(328deg) brightness(94%) contrast(77%);
*/
}


@media (min-width: 991.5px) and (max-width:1500px) {

.infoblock {
 top:250px;
}
}

@media (max-width: 991.5px) {
.infoblock {
top: calc(60vh + 220px);
}

/* wenn es einen infoblock hat: normsection-intro margin-bottom: 180px */

/*
.normsection-intro:has(.infoblock) {
  margin-bottom:150px;
}
*/
}

/* Formular und Button */
@media (min-width: 991px) and (max-width: 1350px) {
.speisewrapper {

flex-direction: column;
align-items: start;


} 
.formwrap {

flex-direction: column;

} 

.halb {
width: 100%;
}

.halb:first-child {
padding-bottom:7px;
}

}
@media (min-width: 991.5px) and (max-width: 1160px) {

.menulinktop {
font-size:36px;
}
.nav {
grid-column-gap: 15px;
}
}
/* @media (max-width: 991.5px) { */
.menubereichcont {

 height: calc(100vh - 310px);
 top: 180px; 
 overflow-y: scroll;
 display:block;
}
.menulinkcontainer {

 margin-bottom:45px;

}

/* } */
@media (max-width: 991.5px) { 
.menubereichcont {

 height: calc(100vh - 220px);
 top: 120px; 
 overflow-y: scroll;
 display:block;
}
} 

@media (max-width: 600px) {
.popupdirektbuchung {

width: calc(100vw - 60px);

}
.popupdirektbuchung .popuph {
width: calc(100vw - 200px);
}

}

@media (max-width: 500px) {


.popupdirektbuchung .popuph{

font-size: 16px;
margin-bottom: 10px;

}
.popupdirektbuchung .listpopup {

font-size: 13px;
line-height: 18px;

}

}

@media (min-width: 991.5px) and (max-width: 1100px) {
.fifty-semitransparent {
padding-left:90px;
}
}


/* dotted list */

.dotted-list {
list-style-type: none;
 padding-left:10px;
 border-left: 10px solid #dcae6b;
 margin-left:2px;
 margin-bottom:0px;
}

.dotted-list li {
overflow: hidden;
padding: 0 0 10px 0;
margin: 0;
position: relative;
}

.dotted-list li span:first-child {
float: left;
display: inline-block;
background-color: #FEFDFB;
padding: 0 20px 0 0;
z-index: 1;
position: relative;
}

.dotted-list li span + strong {
float: right;
display: inline-block;
padding: 0 3px 0 20px;
z-index: 1;
background-color: #FEFDFB;
position: relative;
}

.dotted-list li:before {
content: ".......................................................................................................................................................................................................................................................................................................................................................";
white-space: nowrap;
position: absolute;
}

.yellowdotlist {


list-style: none; /* Remove default bullets */
}
.yellowdotlist li::before {
content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
color: #dcae6b; /* Change the color */
font-weight: bold; /* If you want it to be bold */
display: inline-block; /* Needed to add space between the bullet and the text */
width: 1em; /* Also needed for space (tweak if needed) */
margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.yellowdotlist li {
padding: 0 0 4px 0;
margin: 0;

}
@media (max-width: 767px) {
  .dotted-list li:before {
     content: "";
     white-space: nowrap;
     position: absolute;
  }

 .dotted-list li span + strong {
      display: block;
      width:100%;
      text-align:right;
      margin-bottom:10px;
     border-bottom: 2px dotted #333;

 }
 /*
 .dotted-list li span + strong:before {

     content: ".......................................................................................................................................................................................................................................................................................................................................................";
     white-space: nowrap;
     position: absolute;
  }
  */

}



/* / Dotted list */
a, input, .leistungenoverlay {

transition: 0.3s;
}
#mainnav {

transition: 0.3s;
}
#mainnav:hover {
 background-color: rgba(245, 231, 210, 0.7);
}

/* lenis */



html.lenis {
height: auto;
}

.lenis.lenis-smooth {
scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
overscroll-behavior: contain;
}

.lenis.lenis-stopped {
overflow: hidden;
}

.lenis.lenis-scrolling iframe {
pointer-events: none;
} 



.bestpp::after {
 content: 'mit Best-Price Garantie';
 font-size: 10px;
 display: block;
 font-weight:600;
}

 .bestppeng::after {
 content: 'best price guaranteed';
 font-size: 10px;
 display: block;
 font-weight:600;
}

.curr {
opacity: 0.8;
}
.max1080cont:has(.slider-2) {
 /*
 overflow-x: hidden;
*/
}

@media (max-width:479px) {
 
#mainnav.scrolling {
  background-color: rgba(245, 231, 210, 0.7);
 }
 
}


/* form colors:

.w-input[readonly] {
background-color: transparent;
}
.w-input:focus{
 border-color: #647AA3;
}

.w-input::placeholder { 
color:rgba(185,114,92,0.5);
opacity: 1; 
}

.w-input:-ms-input-placeholder { 
color:rgba(185,114,92,0.5);
}

.w-input::-ms-input-placeholder {
color:rgba(185,114,92,0.5);
}
*/

 .hideges {
 display: none;
}

@keyframes wiggle {
0%, 100% {
 transform: translate(-7.5%)scale(.85)rotate(0deg);
}
25% {
 transform: translate(-7.5%)scale(.85)rotate(-5deg);
}
75% {
 transform: translate(-7.5%)scale(.85)rotate(5deg);
}
}

/* Apply the animation to the element */
.geschenkbutton {
animation: wiggle 0.5s ease-in-out 5s 5; /* Animation name, duration, easing, delay, iteration count */ 
}

@media (min-width: 991.5px) and (max-width: 1124px) {
.oeffnungszeitenblock {
 grid-column-gap: 20px;
 
}
.oeffnungszeitenelem {
    padding-left:10px;
 padding-right:10px;
}
}

/* news float fixes */
.w-richtext.richtextnews figure.w-richtext-align-floatright {
 margin-top:30px;
}
.w-richtext.richtextnews figure.w-richtext-align-floatright div img {

 border-top-right-radius: 100px;
 border-bottom-left-radius: 100px;
}
@media (max-width: 800px) {
 .w-richtext.richtextnews figure.w-richtext-align-floatright {
     float:none;
     margin-left:0px;

 }
}